import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  Search,
  DropdownMenu,
  DropdownItem,
  PullRefresh,
  List,
  Cell,
  CellGroup,
  Card,
  Swipe,
  SwipeItem,
  NavBar,
  Icon,
  Toast,
} from "vant";
import "vant/lib/index.css";
import "./config/rem";
import VueClipboard from "vue-clipboard2";

Vue.use(Toast);
Vue.use(Icon);
Vue.use(Search);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Card);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(NavBar);
Vue.use(VueClipboard);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
